import React from "react";
import "./CloseButton.css";
import CloseImg from "../../assets/close.svg";

const CloseButton = ({ onClose }) => {
  return (
    <a href="#" onClick={() => onClose()} className="close-btn">
      <img src={CloseImg} className="w-100" alt="" />
    </a>
  );
};

export default CloseButton;
