import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMetaMask } from "./utility/useMetamask";
import { formatAddress } from "./utility/Utils.js";
import { MetamaskWarnings } from "./popups/MetamaskWarnings.js";
import DateTimePicker from "react-datetime-picker";
import Footer from "./Footer.jsx";
import { utils } from "ethers";
//css
import "./App.css";
//images
import logo from "./assets/aipo-logo.png";
import connectBtnImg from "./assets/connect-button.png";
import buyBtnImg from "./assets/buy-button.png";
import bgImg from "./assets/bg.png";
import titleImg from "./assets/title.png";
import rateBtnImg from "./assets/rate-btn.png";
import arrowImg from "./assets/mid-arrow.png";

const App = () => {
  const {
    contractInfo,
    wallet,
    connectMetaMask,
    disConnectMetaMask,
    isConnecting,
    pageLoading,
  } = useMetaMask();
  //contractInfo.walletRole: 1:deployer, 2:tradingAcc, 3:user
  const initalMessage = {
    msgType: 0, //1: error, 2: success
    urlTitle: null,
    url: null,
    msg: null,
  };
  const [oneTokenPriceR1, setOneTokenPriceR1] = useState(0);
  const [oneTokenPriceR2, setOneTokenPriceR2] = useState(0);
  const [paymentDecimals, setPaymentDecimals] = useState(0);
  const [tokenDecimals, setTokenDecimals] = useState(0);
  const [paymentSymbol, setPaymentSymbol] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [upperLimitR1, setUpperLimitR1] = useState(0);
  const [upperLimitR2, setUpperLimitR2] = useState(0);
  const [statusR1, setStatusR1] = useState(0);
  const [statusR2, setStatusR2] = useState(0);
  const [maxUserPaymentBalance, setMaxUserPaymentBalance] = useState(0);
  const [maxUserTokenBalance, setMaxUserTokenBalance] = useState(0);
  const [contractPaymentBalance, setContractPaymentBalance] = useState(0);
  const [contractTokenBalance, setContractTokenBalance] = useState(0);
  const [userRound, setUserRound] = useState(0);
  const [userInfo, setUserInfo] = useState([]);
  const [message, setMessage] = useState(initalMessage);
  const [buttonWait, setButtonWait] = useState(false);
  const [wlAddresses, setWlAddresses] = useState([]);
  const [selectedAddressCount, setSelectedAddressCount] = useState(0);
  const [dateTimePickerValue, setDateTimePickerValue] = useState(new Date());

  const usdtRef = useRef();
  const aipoRef = useRef();
  const deployerRoundRef = useRef();
  const cgStatusRef = useRef();
  const utLimitRef = useRef();
  const wlAddRef = useRef();
  const wlRemoveRef = useRef();
  const withdrawRef = useRef();
  //--createStream
  const transferableRef = useRef();
  const cancelableRef = useRef();
  const percentRef = useRef();
  const secondPhaseMonthRef = useRef();

  const getUserInfo = async (round, address) => {
    const whiteListed =
      round === 3 ? false : (round === 1 || round === 2) && true;
    const isClaimed = await contractInfo.contract.isClaimed(round, address);
    const userPayments =
      Number(await contractInfo.contract.userPayments(round, address)) /
      paymentDecimals;
    const userTokensAmount =
      Number((await contractInfo.contract.userTokens(round, address)).amount) /
      tokenDecimals;
    const userTokensStreamId = Number(
      (await contractInfo.contract.userTokens(round, address)).streamId
    );
    console.log(`whiteListed: ${whiteListed}`);
    console.log(`userPayments: ${userPayments} ${paymentSymbol}`);
    console.log(`userTokensAmount: ${userTokensAmount} ${tokenSymbol}`);
    console.log(`userTokensStreamId: ${userTokensStreamId} (Sablier)`);
    console.log(`isClaimed: ${isClaimed}`);
    setUserInfo([
      whiteListed,
      isClaimed,
      !isNaN(userPayments) ? userPayments : 0,
      !isNaN(userTokensAmount) ? userTokensAmount : 0,
      !isNaN(userTokensStreamId) ? userTokensStreamId : 0,
    ]);

    // console.log(await contractInfo.contract.usersPerStreams(1, 0));
    // console.log(await contractInfo.contract.usersPerStreams(1, 1));
    // console.log(await contractInfo.contract.usersPerStreams(1, 2));
    // console.log(await contractInfo.contract.usersPerStreams(2, 0))
    // console.log(await contractInfo.contract.usersPerStreams(2, 1))
  };

  const setPercentBalance = async (rate) => {
    setMessage(initalMessage);
    if (contractInfo.walletRole > 0 && contractInfo.walletRole === 3) {
      if (userRound > 0 && userRound < 3) {
        if (
          (userRound === 1 && statusR1 === 1) ||
          (userRound === 2 && statusR2 === 1)
        ) {
          const amount = Math.floor((rate / 100) * maxUserPaymentBalance);
          usdtRef.current.value = amount;
          console.log(
            `PaymentToken Amount (R${userRound}): ${
              (rate / 100) * maxUserPaymentBalance
            }`
          );
          tokenCalculation(amount);
        } else {
          setMessage({ msgType: 1, msg: "Your round status must be active" });
        }
      } else {
        setMessage({ msgType: 1, msg: "You are not rounds whitelist" });
      }
    } else {
      setMessage({ msgType: 1, msg: "Your wallet is not connected" });
    }
  };

  const checkUserLimit = async (paymentAmount) => {
    let success = false;
    let errorMsg = null;
    const userPayments = Number(
      await contractInfo.contract.userPayments(userRound, wallet.accounts[0])
    );

    if (paymentAmount >= 2 * paymentDecimals) {
      if (userRound === 1) {
        if (userPayments + paymentAmount > upperLimitR1) {
          success = false;
          const availableAmount = upperLimitR1 - userPayments;
          if (availableAmount >= 2 * paymentDecimals) {
            errorMsg = `${Math.floor(
              paymentAmount / paymentDecimals
            )} ${paymentSymbol} maximum amount limit exceeded. Amount available is ${Math.floor(
              availableAmount / paymentDecimals
            )} ${paymentSymbol}`;
          } else {
            errorMsg = "maximum amount limit exceeded";
          }
        } else {
          success = true;
        }
      }

      if (userRound === 2) {
        if (userPayments + paymentAmount > upperLimitR2) {
          success = false;
          const availableAmount = upperLimitR2 - userPayments;
          if (availableAmount >= 2 * paymentDecimals) {
            errorMsg = `${Math.floor(
              paymentAmount / paymentDecimals
            )} ${paymentSymbol} maximum amount limit exceeded. Amount available is ${Math.floor(
              availableAmount / paymentDecimals
            )} ${paymentSymbol}`;
          } else {
            errorMsg = "maximum amount limit exceeded";
          }
        } else {
          success = true;
        }
      }
    } else {
      success = false;
      errorMsg = `must be minimum 2 ${paymentSymbol}`;
    }

    return { success, errorMsg };
  };

  const tokenCalculation = async (_paymentAmount) => {
    setMessage(initalMessage);
    if (contractInfo.walletRole > 0 && contractInfo.walletRole === 3) {
      if (userRound > 0 && userRound < 3) {
        if (
          (userRound === 1 && statusR1 === 1) ||
          (userRound === 2 && statusR2 === 1)
        ) {
          const paymentAmount = _paymentAmount * paymentDecimals;
          const userLimitStatus = await checkUserLimit(paymentAmount);
          let tokenAmount = 0;

          if (paymentAmount >= 2 * paymentDecimals && userLimitStatus.success) {
            if (userRound === 1) {
              tokenAmount = Math.floor(Number(paymentAmount) / oneTokenPriceR1);
              console.log(
                `SaleToken Amount (R${userRound}): ${
                  Number(paymentAmount) / oneTokenPriceR1
                }`
              );
            }
            if (userRound === 2) {
              tokenAmount = Math.floor(Number(paymentAmount) / oneTokenPriceR2);
              console.log(
                `SaleToken Amount (R${userRound}): ${
                  Number(paymentAmount) / oneTokenPriceR2
                }`
              );
            }
            aipoRef.current.value = tokenAmount;
          } else {
            aipoRef.current.value = "";
            setMessage({
              msgType: 1,
              msg: !userLimitStatus.success && userLimitStatus.errorMsg,
            });
          }
        } else {
          setMessage({ msgType: 1, msg: "Your round status must be active" });
        }
      } else {
        setMessage({ msgType: 1, msg: "You are not rounds whitelist" });
      }
    } else {
      setMessage({ msgType: 1, msg: "Your wallet is not connected" });
    }
  };

  const buyTokens = async (_paymentAmount) => {
    setButtonWait(true);
    setMessage(initalMessage);
    const paymentAmount = Number(_paymentAmount);
    if (contractInfo.walletRole > 0 && contractInfo.walletRole === 3) {
      if (userRound > 0 && userRound < 3) {
        await getUpdatableVars();
        if (
          (userRound === 1 && statusR1 === 1) ||
          (userRound === 2 && statusR2 === 1)
        ) {
          const userLimitStatus = await checkUserLimit(
            paymentAmount * paymentDecimals
          );

          if (paymentAmount >= 2 && userLimitStatus.success) {
            console.log(`round: ${userRound}`);
            console.log(
              `paymentAmount: ${
                paymentAmount * paymentDecimals
              } ${paymentSymbol}`
            );
            console.log(`upperLimitR1: ${upperLimitR1}`);
            console.log(`upperLimitR2: ${upperLimitR2}`);

            if (paymentAmount > maxUserPaymentBalance) {
              setButtonWait(false);
              setMessage({
                msgType: 1,
                msg: `Not enough payment token liquidity at your wallet`,
              });
              return;
            }

            const allowanceAmount = Number(
              await contractInfo.paymentContract.allowance(
                wallet.accounts[0],
                process.env.REACT_APP_CONTRACT_ADDRESS
              )
            );

            //approve upperlimit kadar atılacak
            if (
              (userRound === 1 &&
                paymentAmount * paymentDecimals > allowanceAmount) ||
              (userRound === 2 &&
                paymentAmount * paymentDecimals > allowanceAmount)
            ) {
              //approve
              contractInfo.paymentContract
                .approve(
                  process.env.REACT_APP_CONTRACT_ADDRESS,
                  (userRound === 1 && upperLimitR1) ||
                    (userRound === 2 && upperLimitR2)
                )
                .then(async (result) => {
                  const receipt = await result.wait();
                  setMessage({
                    msgType: 2,
                    urlTitle: "view TX",
                    url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                    msg: `${
                      (userRound === 1 &&
                        Math.floor(upperLimitR1 / paymentDecimals)) ||
                      (userRound === 2 &&
                        Math.floor(upperLimitR2 / paymentDecimals))
                    } ${paymentSymbol} Approval`,
                  });
                  console.log(
                    "transactionHash (approve):",
                    receipt.transactionHash
                  );
                  console.log("receipt (approve):", receipt);

                  //buy
                  contractInfo.contract
                    .buyTokens(userRound, paymentAmount)
                    .then(async (result) => {
                      const receipt = await result.wait();
                      setButtonWait(false);
                      if (receipt.events) {
                        for (let i = 0; i < receipt.events.length; i++) {
                          if (receipt.events[i].event === "TokenSold") {
                            setMaxUserPaymentBalance(
                              Number(
                                await contractInfo.paymentContract.balanceOf(
                                  wallet.accounts[0]
                                )
                              ) / paymentDecimals
                            );
                            usdtRef.current.value = "";
                            aipoRef.current.value = "";
                            setMessage({
                              msgType: 2,
                              urlTitle: "view TX",
                              url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                              msg: `Purchased ${(
                                receipt.events[i].args.tokenCount /
                                tokenDecimals
                              ).toFixed(2)} ${tokenSymbol} tokens`,
                            });
                          }
                        }
                      }
                      console.log(
                        "transactionHash (buy):",
                        receipt.transactionHash
                      );
                      console.log("receipt (buy):", receipt);
                      getUserInfo(userRound, wallet.accounts[0]);
                    })
                    .catch((error) => {
                      setButtonWait(false);
                      setMessage({
                        msgType: 1,
                        msg: `(buy) ${error.reason || error.message}`,
                      });
                      console.log("error (buy):", error);
                    });
                })
                .catch((error) => {
                  setButtonWait(false);
                  setMessage({
                    msgType: 1,
                    msg: `(approve) ${error.reason || error.message}`,
                  });
                  console.log("error (approve):", error);
                });
            } else {
              contractInfo.contract
                .buyTokens(userRound, paymentAmount)
                .then(async (result) => {
                  const receipt = await result.wait();
                  setButtonWait(false);
                  if (receipt.events) {
                    for (let i = 0; i < receipt.events.length; i++) {
                      if (receipt.events[i].event === "TokenSold") {
                        setMaxUserPaymentBalance(
                          Number(
                            await contractInfo.paymentContract.balanceOf(
                              wallet.accounts[0]
                            )
                          ) / paymentDecimals
                        );
                        usdtRef.current.value = "";
                        aipoRef.current.value = "";
                        setMessage({
                          msgType: 2,
                          urlTitle: "view TX",
                          url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                          msg: `Purchased ${(
                            receipt.events[i].args.tokenCount / tokenDecimals
                          ).toFixed(2)} ${tokenSymbol} tokens`,
                        });
                      }
                    }
                  }
                  console.log(
                    "transactionHash (buy):",
                    receipt.transactionHash
                  );
                  console.log("receipt (buy):", receipt);
                  getUserInfo(userRound, wallet.accounts[0]);
                })
                .catch((error) => {
                  setButtonWait(false);
                  setMessage({
                    msgType: 1,
                    msg: `(buy) ${error.reason || error.message}`,
                  });
                  console.log("error (buy):", error);
                });
            }
          } else {
            setButtonWait(false);
            setMessage({
              msgType: 1,
              msg: !userLimitStatus.success && userLimitStatus.errorMsg,
            });
          }
        } else {
          setButtonWait(false);
          setMessage({ msgType: 1, msg: "Your round status must be active" });
        }
      } else {
        setButtonWait(false);
        setMessage({ msgType: 1, msg: "You are not rounds whitelist" });
      }
    } else {
      setButtonWait(false);
      setMessage({ msgType: 1, msg: "Your wallet is not connected" });
    }
  };

  const claimTokens = async () => {
    setButtonWait(true);
    setMessage(initalMessage);
    if (contractInfo.walletRole > 0 && contractInfo.walletRole === 3) {
      if (userRound > 0 && userRound < 3) {
        await getUpdatableVars();
        if (
          (userRound === 1 && statusR1 === 2) ||
          (userRound === 2 && statusR2 === 2)
        ) {
          if (userInfo.length > 0 && !userInfo[1]) {
            const contractTokenBalance =
              Number(
                await contractInfo.tokenContract.balanceOf(
                  process.env.REACT_APP_CONTRACT_ADDRESS
                )
              ) / tokenDecimals;
            console.log(
              `Contract Token Balance: ${contractTokenBalance} - User Token: ${userInfo[3]}`
            );
            if (
              contractTokenBalance > 0 &&
              userInfo[3] <= contractTokenBalance
            ) {
              if (userInfo[3] > 0) {
                contractInfo.contract
                  .claimTokens(userRound)
                  .then(async (result) => {
                    const receipt = await result.wait();
                    setButtonWait(false);
                    if (receipt.events) {
                      for (let i = 0; i < receipt.events.length; i++) {
                        if (receipt.events[i].event === "TokensClaimed") {
                          setMessage({
                            msgType: 2,
                            urlTitle: "view TX",
                            url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                            msg: `Claimed ${(
                              receipt.events[i].args.tokenCount / tokenDecimals
                            ).toFixed(2)} ${tokenSymbol} tokens`,
                          });
                        }
                      }
                    }
                    console.log(
                      "transactionHash (claim):",
                      receipt.transactionHash
                    );
                    console.log("receipt (claim):", receipt);
                    getUserInfo(userRound, wallet.accounts[0]);
                  })
                  .catch((error) => {
                    setButtonWait(false);
                    setMessage({
                      msgType: 1,
                      msg: `(claim) ${error.reason || error.message}`,
                    });
                    console.log("error:", error);
                  });
              } else {
                setButtonWait(false);
                setMessage({
                  msgType: 1,
                  msg: `You don't have ${tokenSymbol} tokens`,
                });
              }
            } else {
              setButtonWait(false);
              setMessage({
                msgType: 1,
                msg: `Not enough ${tokenSymbol} token liquidity at this contract`,
              });
            }
          } else {
            setButtonWait(false);
            setMessage({ msgType: 1, msg: "Already Claimed" });
          }
        } else {
          setButtonWait(false);
          setMessage({ msgType: 1, msg: "Your round status must be claim" });
        }
      } else {
        setButtonWait(false);
        setMessage({ msgType: 1, msg: "You are not rounds whitelist" });
      }
    } else {
      setButtonWait(false);
      setMessage({ msgType: 1, msg: "Your wallet is not connected" });
    }
  };

  const addTokenMetamask = async () => {
    const _tokenDecimals = await contractInfo.tokenContract.decimals();
    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: contractInfo.tokenContract.address,
            symbol: tokenSymbol,
            decimals: _tokenDecimals,
            image: process.env.REACT_APP_TOKEN_IMAGE,
          },
        },
      });

      if (wasAdded) {
        setMessage({ msgType: 2, msg: "Thanks for your interest" });
      }
    } catch (error) {
      console.log("tokenAdd:", error);
    }
  };

  const getConstantVars = async () => {
    setOneTokenPriceR1(Number(await contractInfo.contract.oneTokenPriceR1()));
    setOneTokenPriceR2(Number(await contractInfo.contract.oneTokenPriceR2()));
    setPaymentSymbol(await contractInfo.paymentContract.symbol());
    setTokenSymbol(await contractInfo.tokenContract.symbol());
    setPaymentDecimals(
      10 ** Number(await contractInfo.paymentContract.decimals())
    );
    setTokenDecimals(10 ** Number(await contractInfo.tokenContract.decimals()));
  };

  const getUpdatableVars = async () => {
    setUpperLimitR1(Number(await contractInfo.contract.upperLimitR1()));
    setUpperLimitR2(Number(await contractInfo.contract.upperLimitR2()));
    setStatusR1(Number(await contractInfo.contract.statusR1()));
    setStatusR2(Number(await contractInfo.contract.statusR2()));
  };

  const getDataForDeployer = async () => {
    await getUpdatableVars();
    setContractPaymentBalance(
      Number(
        await contractInfo.paymentContract.balanceOf(
          process.env.REACT_APP_CONTRACT_ADDRESS
        )
      ) / paymentDecimals
    );
    setContractTokenBalance(
      Number(
        await contractInfo.tokenContract.balanceOf(
          process.env.REACT_APP_CONTRACT_ADDRESS
        )
      ) / tokenDecimals
    );
  };

  const getDataForTradingAcc = async () => {
    await getUpdatableVars();
    setMaxUserTokenBalance(
      Number(await contractInfo.tokenContract.balanceOf(wallet.accounts[0])) /
        tokenDecimals
    );
    setContractPaymentBalance(
      Number(
        await contractInfo.paymentContract.balanceOf(
          process.env.REACT_APP_CONTRACT_ADDRESS
        )
      ) / paymentDecimals
    );
    setContractTokenBalance(
      Number(
        await contractInfo.tokenContract.balanceOf(
          process.env.REACT_APP_CONTRACT_ADDRESS
        )
      ) / tokenDecimals
    );
  };

  //deployer functions
  const getWlAddresses = async (_round) => {
    const round = _round || Number(deployerRoundRef.current.value);
    let addresses = [];
    const wlCount = Number(
      (await contractInfo.contract.getReadValues(round)).wlCount
    );

    for (let i = 0; i < wlCount; i++) {
      const address = await contractInfo.contract.wlAddresses(round, i);
      const paymentAmount = Math.floor(
        Number(await contractInfo.contract.userPayments(round, address)) /
          paymentDecimals
      );
      const tokenAmount = Math.floor(
        Number(
          (await contractInfo.contract.userTokens(round, address)).amount
        ) / tokenDecimals
      );
      const tokenStreamId = Number(
        (await contractInfo.contract.userTokens(round, address)).streamId
      );

      addresses.push({ address, paymentAmount, tokenAmount, tokenStreamId });
    }

    setWlAddresses(addresses);
  };
  const selectedAddresses = () => {
    let nullArray = [];
    let removeList = [];
    setSelectedAddressCount(
      nullArray.slice.call(wlRemoveRef.current.selectedOptions).length
    );
    nullArray.slice
      .call(wlRemoveRef.current.selectedOptions)
      .forEach((item) => {
        removeList.push(item.value);
      });
    return removeList;
  };
  const changeStatus = async () => {
    setMessage(initalMessage);
    const round = Number(deployerRoundRef.current.value);
    const status = Number(cgStatusRef.current.value);
    if (round !== 0) {
      contractInfo.contract
        .changeStatus(round, status)
        .then(async (result) => {
          const receipt = await result.wait();
          if (receipt.events) {
            for (let i = 0; i < receipt.events.length; i++) {
              if (receipt.events[i].event === "ChangedStatus") {
                await getDataForDeployer();
                setMessage({
                  msgType: 2,
                  urlTitle: "view TX",
                  url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                  msg: `ChangedStatus`,
                });
              }
            }
          }
          console.log("receipt (changeStatus):", receipt);
        })
        .catch((error) => {
          setMessage({
            msgType: 1,
            msg: `(changeStatus) ${error.reason || error.message}`,
          });
          console.log("error:", error);
        });
    } else {
      setMessage({ msgType: 1, msg: `Select round` });
    }
  };
  const updateLimits = async () => {
    setMessage(initalMessage);
    const round = Number(deployerRoundRef.current.value);
    const limit = Number(utLimitRef.current.value);
    if (round !== 0 && limit > 0) {
      round === 1 &&
        contractInfo.contract
          .updateTokenLimit(limit, 0)
          .then(async (result) => {
            const receipt = await result.wait();
            if (receipt.events) {
              for (let i = 0; i < receipt.events.length; i++) {
                if (receipt.events[i].event === "UpdatedLimit") {
                  await getDataForDeployer();
                  setMessage({
                    msgType: 2,
                    urlTitle: "view TX",
                    url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                    msg: `UpdatedLimitR1`,
                  });
                }
              }
            }
            console.log("receipt (UpdatedLimitR1):", receipt);
          })
          .catch((error) => {
            setMessage({
              msgType: 1,
              msg: `(UpdatedLimitR1) ${error.reason || error.message}`,
            });
            console.log("error:", error);
          });

      round === 2 &&
        contractInfo.contract
          .updateTokenLimit(0, limit)
          .then(async (result) => {
            const receipt = await result.wait();
            if (receipt.events) {
              for (let i = 0; i < receipt.events.length; i++) {
                if (receipt.events[i].event === "UpdatedLimit") {
                  await getDataForDeployer();
                  setMessage({
                    msgType: 2,
                    urlTitle: "view TX",
                    url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                    msg: `UpdatedLimitR2`,
                  });
                }
              }
            }
            console.log("receipt (UpdatedLimitR2):", receipt);
          })
          .catch((error) => {
            setMessage({
              msgType: 1,
              msg: `(UpdatedLimitR2) ${error.reason || error.message}`,
            });
            console.log("error:", error);
          });
    } else {
      setMessage({ msgType: 1, msg: `Select round and limit` });
    }
  };
  const addWL = async () => {
    setMessage(initalMessage);
    const round = Number(deployerRoundRef.current.value);
    let addresses = [];

    if (round !== 0 && wlAddRef.current.value) {
      const addList = wlAddRef.current.value.split(",");
      addList.forEach((item) => {
        addresses.push(item.trim());
      });

      if (
        (round === 1 && Number(await contractInfo.contract.statusR1()) === 0) ||
        (round === 2 && Number(await contractInfo.contract.statusR2()) === 0)
      ) {
        contractInfo.contract
          .addWL(round, addresses)
          .then(async (result) => {
            const receipt = await result.wait();
            if (receipt.events) {
              for (let i = 0; i < receipt.events.length; i++) {
                if (receipt.events[i].event === "AddedWL") {
                  await getDataForDeployer();
                  await getWlAddresses(round);
                  setMessage({
                    msgType: 2,
                    urlTitle: "view TX",
                    url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                    msg: `AddedWL (total address: ${receipt.events[i].args.totalSentCount} -success: ${receipt.events[i].args.successCount} -fail: ${receipt.events[i].args.failCount})`,
                  });
                }
              }
            }
            console.log("receipt (AddedWL):", receipt);
          })
          .catch((error) => {
            setMessage({
              msgType: 1,
              msg: `(AddedWL) ${error.reason || error.message}`,
            });
            console.log("error:", error);
          });
      } else {
        setMessage({
          msgType: 1,
          msg: `Round ${round} status must be deactive`,
        });
      }
    } else {
      setMessage({ msgType: 1, msg: `Select round and write address` });
    }
  };
  const removeWL = async () => {
    setMessage(initalMessage);
    const round = Number(deployerRoundRef.current.value);
    const addresses = selectedAddresses();

    if (round !== 0 && addresses.length > 0) {
      if (
        (round === 1 && Number(await contractInfo.contract.statusR1()) === 0) ||
        (round === 2 && Number(await contractInfo.contract.statusR2()) === 0)
      ) {
        contractInfo.contract
          .removeWL(round, addresses)
          .then(async (result) => {
            const receipt = await result.wait();
            if (receipt.events) {
              for (let i = 0; i < receipt.events.length; i++) {
                if (receipt.events[i].event === "RemovedWL") {
                  await getDataForDeployer();
                  await getWlAddresses(round);
                  setMessage({
                    msgType: 2,
                    urlTitle: "view TX",
                    url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                    msg: `RemovedWL (total address: ${receipt.events[i].args.totalSentCount} -success: ${receipt.events[i].args.successCount} -fail: ${receipt.events[i].args.failCount})`,
                  });
                }
              }
            }
            console.log("receipt (RemovedWL):", receipt);
          })
          .catch((error) => {
            setMessage({
              msgType: 1,
              msg: `(RemovedWL) ${error.reason || error.message}`,
            });
            console.log("error:", error);
          });
      } else {
        setMessage({
          msgType: 1,
          msg: `Round ${round} status must be deactive`,
        });
      }
    } else {
      setMessage({ msgType: 1, msg: `Select round and address` });
    }
  };
  const withdraw = async () => {
    setMessage(initalMessage);
    const wdAddress = withdrawRef.current.value.trim();
    if (wdAddress) {
      contractInfo.contract
        .withdraw(wdAddress)
        .then(async (result) => {
          const receipt = await result.wait();
          if (receipt.events) {
            for (let i = 0; i < receipt.events.length; i++) {
              if (receipt.events[i].event === "withdrawn") {
                await getDataForDeployer();
                setMessage({
                  msgType: 2,
                  urlTitle: "view TX",
                  url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                  msg: `withdrawn (Payment Amount: ${(
                    receipt.events[i].args.paymentTokenAmount / paymentDecimals
                  ).toFixed(2)}, Token Amount: ${(
                    receipt.events[i].args.saleTokenAmount / tokenDecimals
                  ).toFixed(2)})`,
                });
              }
            }
          }
          console.log("receipt (withdrawn):", receipt);
        })
        .catch((error) => {
          setMessage({
            msgType: 1,
            msg: `(withdrawn) ${error.reason || error.message}`,
          });
          console.log("error:", error);
        });
    } else {
      setMessage({ msgType: 1, msg: `Write address` });
    }
  };
  const getSecondTimes = (timestamp) => {
    let addDays = 0;
    const datetime = new Date(timestamp);
    const year = datetime.getFullYear();
    const month = datetime.getMonth() + 1;
    switch (month) {
      case 1:
        addDays = 31;
        break;
      case 2:
        year % 4 === 0 ? (addDays = 29) : (addDays = 28); //2024 || 2028 || 2032...
        break;
      case 3:
        addDays = 31;
        break;
      case 4:
        addDays = 30;
        break;
      case 5:
        addDays = 31;
        break;
      case 6:
        addDays = 30;
        break;
      case 7:
        addDays = 31;
        break;
      case 8:
        addDays = 31;
        break;
      case 9:
        addDays = 30;
        break;
      case 10:
        addDays = 31;
        break;
      case 11:
        addDays = 30;
        break;
      case 12:
        addDays = 31;
        break;
    }
    //1 day = 86400 sec
    return timestamp + addDays * 86400 * 1000; //*1000 for milisec
  };
  const createStreams = async () => {
    const round = Number(deployerRoundRef.current.value);
    const transferable = Boolean(Number(transferableRef.current.value));
    const cancelable = Boolean(Number(cancelableRef.current.value));
    const percent = Number(percentRef.current.value);
    const secondPhaseMonth =
      Number(secondPhaseMonthRef.current.value) > 0
        ? Number(secondPhaseMonthRef.current.value)
        : 1;
    let unlockTimes = [];

    if ((round === 1 && statusR1 === 2) || (round === 2 && statusR2 === 2)) {
      const contractTokenBalance = Number(
        await contractInfo.tokenContract.balanceOf(
          process.env.REACT_APP_CONTRACT_ADDRESS
        )
      );

      let totalAmount = 0;
      for (
        let i = 0;
        i <
        Number(
          (await contractInfo.contract.getReadValues(round))
            .usersPerStreamsCount
        );
        i++
      ) {
        totalAmount =
          totalAmount +
          Number(
            (await contractInfo.contract.usersPerStreams(round, i)).amount
          );
      }

      if (contractTokenBalance > 0 && totalAmount <= contractTokenBalance) {
        if (percent >= 100 && percent <= 10000) {
          if (dateTimePickerValue) {
            console.log("First Phase Unlock Time:", dateTimePickerValue);
            unlockTimes.push(Math.floor(dateTimePickerValue.getTime() / 1000)); //first unlock time

            let nextMonth = getSecondTimes(dateTimePickerValue.getTime());
            console.log(1, ">", nextMonth, new Date(nextMonth)); //second phase 1. month
            unlockTimes.push(Math.floor(nextMonth / 1000));

            for (let i = 1; i < secondPhaseMonth; i++) {
              nextMonth = getSecondTimes(nextMonth);
              console.log(i + 1, ">", nextMonth, new Date(nextMonth));
              unlockTimes.push(Math.floor(nextMonth / 1000));
            }
            console.log(unlockTimes);
          }

          //createBatchDynamic_MilestonesMonthly
          contractInfo.contract
            .createStreams(
              round,
              percent,
              unlockTimes,
              secondPhaseMonth,
              cancelable,
              transferable
            )
            .then(async (result) => {
              const receipt = await result.wait();
              if (receipt.events) {
                for (let i = 0; i < receipt.events.length; i++) {
                  if (receipt.events[i].event === "CreatedBatchDynamicStream") {
                    console.log("streamIds:", receipt.events[i].args.streamIds);
                    setMessage({
                      msgType: 2,
                      urlTitle: "view TX",
                      url: `${process.env.REACT_APP_NETWORKURL}tx/${receipt.transactionHash}`,
                      msg: `totalAmount: ${(
                        receipt.events[i].args.totalAmount / tokenDecimals
                      ).toFixed(2)}, totalDifferenceAmount: ${
                        receipt.events[i].args.differenceAmount
                      }, streamCount: ${receipt.events[i].args.streamCount}`,
                    });
                  }
                }
              }
              console.log("receipt (CreateStream):", receipt);
            })
            .catch((error) => {
              setMessage({
                msgType: 1,
                msg: `(CreateStream) ${error.reason || error.message}`,
              });
              console.log("error (CreateStream):", error);
            });
        } else {
          setMessage({
            msgType: 1,
            msg: `Percentage must be min 100 max 10000`,
          });
        }
      } else {
        setMessage({
          msgType: 1,
          msg: `Not enough ${tokenSymbol} token liquidity at this contract`,
        });
      }
    } else {
      if (round > 0) {
        setMessage({ msgType: 1, msg: "Your round status must be claim" });
      } else {
        setMessage({ msgType: 1, msg: "You must select round" });
      }
    }
  };

  useEffect(() => {
    if (wallet.accounts.length > 0) {
      console.log("Account changed");
      setMaxUserPaymentBalance(0);
      setMaxUserTokenBalance(0);
      setUserRound(0);
      setUserInfo([]);
      setMessage(initalMessage);
      setWlAddresses([]);
      setSelectedAddressCount(0);
    }
  }, [wallet.accounts]);

  useEffect(() => {
    if (contractInfo.contract && paymentDecimals < 1) {
      getConstantVars();
      getUpdatableVars();
    }

    if (!isConnecting && wallet.accounts.length < 1) {
      console.log("Page has been reset");
      setMaxUserPaymentBalance(0);
      setMaxUserTokenBalance(0);
      setContractPaymentBalance(0);
      setContractTokenBalance(0);
      setStatusR1(0);
      setStatusR2(0);
      setUserRound(0);
      setUserInfo([]);
      setMessage(initalMessage);
    }
  }, [contractInfo.contract, isConnecting, wallet.accounts]);

  useEffect(() => {
    async function setMaxUserPayment() {
      if (
        contractInfo.paymentContract &&
        wallet.accounts[0] &&
        paymentDecimals > 0
      ) {
        setMessage(initalMessage);
        setMaxUserPaymentBalance(
          Number(
            await contractInfo.paymentContract.balanceOf(wallet.accounts[0])
          ) / paymentDecimals
        );
      }
    }

    try {
      setMaxUserPayment();
    } catch (error) {
      console.error("render:", error);
    }
  }, [contractInfo.paymentContract, wallet.accounts, paymentDecimals]);

  //set userInfo
  useEffect(() => {
    if (
      contractInfo.contract &&
      wallet.accounts[0] &&
      paymentDecimals > 0 &&
      tokenDecimals > 0
    ) {
      if (contractInfo.walletRole === 1) {
        getDataForDeployer();
      }
      if (contractInfo.walletRole === 2) {
        getDataForTradingAcc();
      }
      if (contractInfo.walletRole === 3) {
        const round =
          contractInfo.userWhiteListedR1 === true
            ? 1
            : contractInfo.userWhiteListedR2 === true
            ? 2
            : 3;
        setMessage(initalMessage);
        setUserRound(round);
        getUserInfo(round, wallet.accounts[0]);
      }
    }
  }, [contractInfo.contract, paymentDecimals, tokenDecimals]);

  // console.log(
  //   `walletRole:${contractInfo.walletRole} - userRound: ${userRound} - statusR1:${statusR1} - statusR2:${statusR2}`
  // );

  return (
    <div className="App">
      <MetamaskWarnings />
      <div className="col-12 bg-color-white padding-1-3 d-flex justify-content-between align-items-center">
        <div className="col-md-2 col-4">
          <img className="w-100" src={logo} alt="" />
        </div>
        <div
          className="col-md-1 col-3 position-relative cursor-pointer connect-button-area"
          onClick={() =>
            wallet.accounts.length < 1
              ? connectMetaMask()
              : disConnectMetaMask()
          }
          title={
            wallet.accounts.length < 1 ? "Connect Wallet" : "Disconnect Wallet"
          }
        >
          <img className="w-100" src={connectBtnImg} alt="" />
          <div className="fz-075cqw fw600 div-center text-center w-100">
            {wallet.accounts.length < 1
              ? "Connect Wallet"
              : formatAddress(wallet.accounts[0])}
          </div>
        </div>
      </div>
      <div className="col-12 position-relative">
        <img className="w-100 cover h-100vh" src={bgImg} alt="" />
        <div className="position-absolute top-0 d-flex w-100">
          <div className="col-md-4 col-2"></div>
          <div className="col-md-4 col-8">
            <div className="padding-5-10">
              <img className="w-100" src={titleImg} alt="" />
            </div>
            <div className="position-relative content-area">
              {pageLoading && (
                <div className="d-flex w-100 justify-content-center align-items-center">
                  <span
                    className="spinner-grow text-white position-absolute"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              )}
              {contractInfo.walletRole === 0 ||
              contractInfo.walletRole === 3 ? (
                //USER SCREEN
                <div className="w-100 padding-5">
                  {userRound === 0 ||
                  userRound === 3 ||
                  (userRound === 1 && statusR1 === 0) ||
                  (userRound === 2 && statusR2 === 0) ||
                  (userRound === 1 && statusR1 === 1) ||
                  (userRound === 2 && statusR2 === 1) ? (
                    //Status 0 or 1 (Deactive or Active)
                    <>
                      {userRound === 3 ? (
                        <div className="text-danger fz-1cqw fw600 text-center bg-color-white border-radius-8">
                          You are not rounds whitelist!
                        </div>
                      ) : (
                        userRound > 0 &&
                        userRound < 3 && (
                          <div className="text-white fz-1cqw fw600 text-center">
                            Your Round: {userRound}
                          </div>
                        )
                      )}
                      <div className="text-white fz-1cqw fw600 text-center">
                        {userRound === 1 &&
                          statusR1 === 0 &&
                          contractInfo.contract &&
                          `Status: DEACTIVE`}
                        {userRound === 1 && statusR1 === 1 && `Status: ACTIVE`}
                        {userRound === 2 &&
                          statusR2 === 0 &&
                          contractInfo.contract &&
                          `Status: DEACTIVE`}
                        {userRound === 2 && statusR2 === 1 && `Status: ACTIVE`}
                      </div>
                      <div className="w-100 fz-075cqw text-white margin-b-5">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Consectetur perspiciatis, recusandae molestiae id
                        mollitia facilis aut qui impedit doloremque est rerum
                        facere dolorem, quisquam a debitis? Consequuntur quod
                        molestias nam?
                      </div>
                      <div className="w-100 margin-b-2">
                        <input
                          className="form-control"
                          placeholder="USDT"
                          aria-label="USDT"
                          onChange={(e) => tokenCalculation(e.target.value)}
                          onKeyDown={(e) =>
                            (e.key === "." || e.key === ",") &&
                            e.preventDefault()
                          }
                          onInput={(e) =>
                            (e.target.value = e.target.value.replace(
                              /[^0-9]*/g,
                              ""
                            ))
                          }
                          ref={usdtRef}
                        />
                        <div className="w-100 fz-075cqw text-white padding-l-2 padding-t-1">
                          Available Balance: {maxUserPaymentBalance.toFixed(2)}{" "}
                          {paymentSymbol && `(${paymentSymbol})`}
                        </div>
                      </div>
                      <div className="w-100 margin-b-5">
                        <div className="d-flex align-items-center w-100">
                          <div
                            onClick={() => setPercentBalance(25)}
                            className="cursor-pointer padding-1-2 position-relative"
                          >
                            <img className="w-100" src={rateBtnImg} alt="" />
                            <div className="fz-1cqw fw600 padding-l-10 div-center text-dark text-center d-flex justify-content-center align-items-center h-100">
                              25%
                            </div>
                          </div>
                          <div
                            onClick={() => setPercentBalance(50)}
                            className="cursor-pointer padding-1-2 position-relative"
                          >
                            <img className="w-100" src={rateBtnImg} alt="" />
                            <div className="fz-1cqw fw600 padding-l-10 div-center text-dark text-center d-flex justify-content-center align-items-center h-100">
                              50%
                            </div>
                          </div>
                          <div className="padding-1-3 position-relative">
                            <img className="w-100" src={arrowImg} alt="" />
                          </div>
                          <div
                            onClick={() => setPercentBalance(75)}
                            className="cursor-pointer padding-1-2 position-relative"
                          >
                            <img className="w-100" src={rateBtnImg} alt="" />
                            <div className="fz-1cqw fw600 padding-l-10 div-center text-dark text-center d-flex justify-content-center align-items-center h-100">
                              75%
                            </div>
                          </div>
                          <div
                            onClick={() => setPercentBalance(100)}
                            className="cursor-pointer padding-1-2 position-relative"
                          >
                            <img className="w-100" src={rateBtnImg} alt="" />
                            <div className="fz-1cqw fw600 padding-l-10 div-center text-dark text-center d-flex justify-content-center align-items-center h-100">
                              100%
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-100 margin-b-5">
                        <input
                          className="form-control"
                          placeholder="AIPO TOKEN AMOUNT"
                          aria-label="AIPO"
                          disabled
                          ref={aipoRef}
                          // value={aipo}
                        />
                      </div>
                      <div
                        className={
                          message.msgType === 1
                            ? "w-100 fz-075cqw padding-0-1 border-radius-8 bg-color-white text-danger"
                            : message.msgType === 2
                            ? "w-100 fz-075cqw padding-0-1 border-radius-8 bg-color-white text-success"
                            : "w-100 fz-075cqw padding-0-1 border-radius-8 bg-color-white text-white"
                        }
                      >
                        {message.msgType > 0 && (
                          <>
                            {message.msgType === 1
                              ? `Error: ${message.msg} `
                              : `Success: ${message.msg} `}
                            {message.url && (
                              <Link
                                to={message.url}
                                target="_blank"
                                className="padding-0-1"
                              >
                                {message.urlTitle}
                              </Link>
                            )}
                          </>
                        )}
                      </div>
                      <div className="w-100 margin-t-3 margin-b-2">
                        <div className="d-flex w-100 justify-content-center align-items-center">
                          <div
                            className={
                              !buttonWait
                                ? "cursor-pointer col-4 col-md-3 position-relative"
                                : "cursor-pointer col-4 col-md-3 position-relative disabled"
                            }
                            onClick={() =>
                              wallet.accounts.length > 0
                                ? buyTokens(usdtRef.current.value)
                                : connectMetaMask()
                            }
                          >
                            <img className="w-100" src={buyBtnImg} alt="" />
                            <div className="fz-1-5cqw padding-t-3 div-center w-100 text-white text-center bigmacca">
                              {!buttonWait ? (
                                "BUY"
                              ) : (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="w-100 fz-075cqw text-white margin-b-0 grid">
                          <div className="grid-item">
                            WhiteListed:
                            <div
                              className={
                                userInfo[0]
                                  ? "fw700 padding-l-2 text-success"
                                  : "fw700 padding-l-2 text-danger"
                              }
                            >
                              {userInfo.length > 0 &&
                                JSON.stringify(userInfo[0])}
                            </div>
                          </div>
                          <div className="grid-item">
                            Total Payments:
                            <div className="fw700 padding-l-2">
                              {userInfo.length > 0 &&
                                `${userInfo[2].toFixed(2)} ${paymentSymbol}`}
                            </div>
                          </div>
                          <div className="grid-item">
                            Claimed:
                            <div
                              className={
                                userInfo[1]
                                  ? "fw700 padding-l-2 text-success"
                                  : "fw700 padding-l-2 text-danger"
                              }
                            >
                              {userInfo.length > 0 &&
                                JSON.stringify(userInfo[1])}
                            </div>
                          </div>
                          <div className="grid-item">
                            Total Tokens:
                            <div className="fw700 padding-l-2">
                              {userInfo.length > 0 &&
                                `${userInfo[3].toFixed(2)} ${tokenSymbol}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {(userRound === 1 && statusR1 === 2) ||
                  (userRound === 2 && statusR2 === 2) ? (
                    //Status 2 (CLAIM)
                    <>
                      {userRound === 3 ? (
                        <div className="text-danger fz-1cqw fw600 text-center bg-color-white border-radius-8">
                          You are not rounds whitelist!
                        </div>
                      ) : (
                        userRound > 0 &&
                        userRound < 3 && (
                          <div className="text-white fz-1cqw fw600 text-center">
                            Your Round: {userRound}
                          </div>
                        )
                      )}
                      <div className="text-white fz-1cqw fw600 text-center">
                        {userRound === 1 && statusR1 === 2 && `Status: CLAIM`}
                        {userRound === 2 && statusR2 === 2 && `Status: CLAIM`}
                      </div>
                      <div className="w-100 fz-075cqw text-white margin-b-5">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Consectetur perspiciatis, recusandae molestiae id
                        mollitia facilis aut qui impedit doloremque est rerum
                        facere dolorem, quisquam a debitis? Consequuntur quod
                        molestias nam?
                      </div>
                      <div
                        className={
                          userInfo.length > 0 && userInfo[1]
                            ? "fz-1-5cqw fw700 text-center text-success"
                            : "fz-1-5cqw fw700 text-center text-white"
                        }
                      >
                        {userInfo.length > 0 && userInfo[1]
                          ? "Claimed"
                          : "Amount:"}
                      </div>
                      <div
                        className={
                          userInfo.length > 0 && userInfo[1]
                            ? "fz-1-5cqw fw700 text-center text-success"
                            : "fz-1-5cqw fw700 text-center text-white"
                        }
                      >
                        {userInfo.length > 0 &&
                          `${userInfo[3].toFixed(2)} ${tokenSymbol}`}
                        {userInfo.length > 0 && userInfo[1] && (
                          <div className="d-flex w-100 justify-content-center align-items-center margin-b-5">
                            <div
                              className="fz-075cqw fw600 cursor-pointer bg-color-white padding-0-1 border-radius-8 text-primary"
                              onClick={() => addTokenMetamask()}
                            >
                              Add tokens to metamask
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          message.msgType === 1
                            ? "w-100 fz-075cqw padding-0-1 border-radius-8 bg-color-white text-danger"
                            : message.msgType === 2
                            ? "w-100 fz-075cqw padding-0-1 border-radius-8 bg-color-white text-success"
                            : "w-100 fz-075cqw padding-0-1 border-radius-8 bg-color-white text-white"
                        }
                      >
                        {message.msgType > 0 && (
                          <>
                            {message.msgType === 1
                              ? `Error: ${message.msg} `
                              : `Success: ${message.msg} `}
                            {message.url && (
                              <Link
                                to={message.url}
                                target="_blank"
                                className="padding-0-1"
                              >
                                {message.urlTitle}
                              </Link>
                            )}
                          </>
                        )}
                      </div>
                      <div className="w-100 margin-t-3 margin-b-2">
                        <div className="d-flex w-100 justify-content-center align-items-center margin-b-5">
                          {userInfo[1] ? (
                            <Link
                              className="cursor-pointer col-4 col-md-3 position-relative"
                              title="View Stream on Sablier"
                              to={`https://app.sablier.com/stream/LD2-${process.env.REACT_APP_NETWORKVERSION}-${userInfo[4]}`}
                              target="blank"
                            >
                              <img className="w-100" src={buyBtnImg} alt="" />
                              <div className="fz-1cqw padding-t-3 div-center w-100 text-white text-center bigmacca">
                                View Stream
                              </div>
                            </Link>
                          ) : (
                            <p className="text-success bg-color-white fz-1cqw fw600 text-center padding-0-1 border-radius-8">
                              Waiting for create sablier stream
                            </p>
                          )}

                          {/* <div
                            className={
                              !buttonWait
                                ? "cursor-pointer col-4 col-md-3 position-relative"
                                : "cursor-pointer col-4 col-md-3 position-relative disabled"
                            }
                            onClick={() =>
                              wallet.accounts.length > 0
                                ? claimTokens()
                                : connectMetaMask()
                            }
                          >
                            <img className="w-100" src={buyBtnImg} alt="" />
                            <div className="fz-1-5cqw padding-t-3 div-center w-100 text-white text-center bigmacca">
                              {!buttonWait ? (
                                "CLAIM"
                              ) : (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </div>
                          </div> */}
                        </div>
                        <div className="w-100 fz-075cqw text-white margin-b-0 grid">
                          <div className="grid-item">
                            WhiteListed:
                            <div
                              className={
                                userInfo[0]
                                  ? "fw700 padding-l-2 text-success"
                                  : "fw700 padding-l-2 text-danger"
                              }
                            >
                              {userInfo.length > 0 &&
                                JSON.stringify(userInfo[0])}
                            </div>
                          </div>
                          <div className="grid-item">
                            Total Payments:
                            <div className="fw700 padding-l-2">
                              {userInfo.length > 0 &&
                                `${userInfo[2].toFixed(2)} ${paymentSymbol}`}
                            </div>
                          </div>
                          <div className="grid-item">
                            Claimed:
                            <div
                              className={
                                userInfo[1]
                                  ? "fw700 padding-l-2 text-success"
                                  : "fw700 padding-l-2 text-danger"
                              }
                            >
                              {userInfo.length > 0 &&
                                JSON.stringify(userInfo[1])}
                            </div>
                          </div>
                          <div className="grid-item">
                            Total Tokens:
                            <div className="fw700 padding-l-2">
                              {userInfo.length > 0 &&
                                `${userInfo[3].toFixed(2)} ${tokenSymbol}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : contractInfo.walletRole === 1 ? (
                //DEPLOYER SCREEN
                <div className="w-100 padding-5">
                  <div className="text-white fz-1-5cqw fw700 text-center margin-b-2">
                    {contractInfo.walletRole > 0 && `DEPLOYER`}
                  </div>
                  <div className="text-white fz-1cqw fw600 text-center margin-b-2">
                    <select
                      ref={deployerRoundRef}
                      defaultValue={0}
                      onChange={() => getWlAddresses()}
                      className="form-control-sm"
                    >
                      <option value="0">Select Round</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>
                  <div className="row text-white fz-075cqw fw600 text-center margin-b-2">
                    <div className="col-md-9 col-8">
                      <select
                        ref={cgStatusRef}
                        defaultValue={0}
                        className="w-100 fz-075cqw form-control-deployer form-control-sm"
                      >
                        <option value="0">Deactive</option>
                        <option value="1">Active</option>
                        <option value="2">Claim</option>
                      </select>
                    </div>
                    <div className="col-md-3 col-4">
                      <button
                        className="w-100 h-100 btn btn-dark btn-sm fz-075cqw"
                        onClick={() => changeStatus()}
                      >
                        CHANGE
                      </button>
                    </div>
                  </div>
                  <div className="row text-white fz-075cqw margin-b-2">
                    <div className="col-md-9 col-8">
                      <input
                        type="number"
                        ref={utLimitRef}
                        className="w-100 fz-075cqw form-control-deployer form-control-sm"
                        placeholder={`Update Token Limit (${paymentSymbol})`}
                        onKeyDown={(e) =>
                          (e.key === "." || e.key === ",") && e.preventDefault()
                        }
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^0-9]*/g,
                            ""
                          ))
                        }
                      />
                    </div>
                    <div className="col-md-3 col-4">
                      <button
                        className="w-100 h-100 btn btn-dark btn-sm fz-075cqw"
                        type="button"
                        onClick={() => updateLimits()}
                      >
                        SET LIMIT
                      </button>
                    </div>
                  </div>
                  <div className="row fz-075cqw text-white margin-b-2">
                    <div className="col-md-9 col-8">
                      <textarea
                        className="w-100 fz-075cqw form-control-deployer form-control-sm"
                        ref={wlAddRef}
                        placeholder="Add whitelist e.g.: 0xAbc, 0xAbc, ..."
                      />
                    </div>
                    <div className="col-md-3 col-4">
                      <button
                        type="button"
                        className="w-100 h-100 btn btn-dark btn-sm fz-075cqw"
                        onClick={() => addWL()}
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                  <div className="row fz-075cqw text-white margin-b-2">
                    <div className="col-md-9 col-8">
                      <select
                        ref={wlRemoveRef}
                        className="w-100 fz-075cqw form-control-deployer form-control-sm"
                        multiple
                        aria-label="multiple select example"
                        onClick={() => selectedAddresses()}
                      >
                        {wlAddresses.length > 0 &&
                          wlAddresses.map((item, index) => (
                            <option value={item.address} key={index}>
                              {item.address}
                            </option>
                          ))}
                      </select>
                      Whitelist:{" "}
                      {wlAddresses.length > 0 && (
                        <b>{`${wlAddresses.length} addresses in R${deployerRoundRef.current.value} whitelist`}</b>
                      )}
                    </div>
                    <div className="col-md-3 col-4">
                      <button
                        type="button"
                        className="w-100 h-80 btn btn-dark btn-sm fz-075cqw"
                        onClick={() => removeWL()}
                      >
                        ({selectedAddressCount}) SELECTED REMOVE
                      </button>
                    </div>
                  </div>
                  <div className="row fz-075cqw text-white margin-b-2">
                    <div className="col-md-9 col-8">
                      <input
                        type="text"
                        ref={withdrawRef}
                        className="w-100 fz-075cqw form-control-deployer form-control-sm"
                        placeholder="Withdraw recipient address (0xAbc..)"
                      />
                    </div>
                    <div className="col-md-3 col-4">
                      <button
                        className="w-100 h-100 btn btn-dark btn-sm fz-075cqw"
                        onClick={() => withdraw()}
                      >
                        SEND
                      </button>
                    </div>
                  </div>
                  <div className="row fz-075cqw text-white margin-b-2">
                    <ul
                      className="list-group"
                      style={{
                        paddingLeft: "12px",
                        maxHeight: "100px",
                        overflow: "scroll",
                      }}
                    >
                      {wlAddresses.length > 0 &&
                        wlAddresses.map(
                          (item, index) =>
                            item.tokenAmount > 0 && (
                              <li
                                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center padding-1"
                                key={index}
                              >
                                <Link
                                  to={`${process.env.REACT_APP_NETWORKURL}address/${item.address}`}
                                  target="blank"
                                >
                                  {index + 1} - {item.address}
                                </Link>
                                <span className="badge bg-dark rounded-pill">
                                  {item.tokenAmount} {tokenSymbol} (
                                  {item.paymentAmount} {paymentSymbol})
                                </span>
                              </li>
                            )
                        )}
                    </ul>
                  </div>
                  <div className="row fz-075cqw text-white margin-b-2">
                    <div className="col-md-9 col-8">
                      <div className="fz-075cqw text-black margin-b-1 bg-color-white padding-1-2">
                        First Phase Unlock Time:
                        <DateTimePicker
                          onChange={setDateTimePickerValue}
                          value={dateTimePickerValue}
                          minDate={new Date()}
                        />
                      </div>
                      <select
                        ref={transferableRef}
                        defaultValue={2}
                        className="w-100 fz-075cqw form-control-deployer form-control-sm margin-b-1"
                      >
                        <option value="2">Transferable</option>
                        <option value="1">true</option>
                        <option value="0">false</option>
                      </select>
                      <select
                        ref={cancelableRef}
                        defaultValue={2}
                        className="w-100 fz-075cqw form-control-deployer form-control-sm margin-b-1"
                      >
                        <option value="2">Cancelable</option>
                        <option value="1">true</option>
                        <option value="0">false</option>
                      </select>
                      <input
                        type="number"
                        ref={percentRef}
                        className="w-100 fz-075cqw form-control-deployer form-control-sm margin-b-1"
                        placeholder="Percentage (e.g.: 1500 for %15)"
                        onKeyDown={(e) =>
                          (e.key === "." || e.key === ",") && e.preventDefault()
                        }
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^0-9]*/g,
                            ""
                          ))
                        }
                      />
                      <input
                        type="number"
                        ref={secondPhaseMonthRef}
                        className="w-100 fz-075cqw form-control-deployer form-control-sm margin-b-1"
                        placeholder="Second Phase Monthly (min 1 month)"
                        min={1}
                        onKeyDown={(e) =>
                          (e.key === "." || e.key === ",") && e.preventDefault()
                        }
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^0-9]*/g,
                            ""
                          ))
                        }
                      />
                    </div>
                    <div className="col-md-3 col-4">
                      <button
                        className="w-100 h-100 btn btn-dark btn-sm fz-075cqw"
                        onClick={() => createStreams()}
                      >
                        CREATE STREAMS
                      </button>
                    </div>
                  </div>
                  <div
                    className={
                      message.msgType === 1
                        ? "w-100 fz-075cqw padding-0-1 border-radius-8 bg-color-white text-danger"
                        : message.msgType === 2
                        ? "w-100 fz-075cqw padding-0-1 border-radius-8 bg-color-white text-success"
                        : "w-100 fz-075cqw padding-0-1 border-radius-8 bg-color-white text-white"
                    }
                  >
                    {message.msgType > 0 && (
                      <>
                        {message.msgType === 1
                          ? `Error: ${message.msg} `
                          : `Success: ${message.msg} `}
                        {message.url && (
                          <Link
                            to={message.url}
                            target="_blank"
                            className="padding-0-1"
                          >
                            {message.urlTitle}
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                  <div className="w-100 fz-1cqw margin-t-5 text-white">
                    <div className="d-flex w-100 justify-content-center align-items-center">
                      <Link
                        to={`${process.env.REACT_APP_NETWORKURL}address/${process.env.REACT_APP_CONTRACT_ADDRESS}`}
                        target="_blank"
                        className="padding-0-1 bg-color-white border-radius-8"
                      >
                        view Private Sale Contract
                      </Link>
                    </div>
                    <div className="w-100 text-white text-center">
                      {paymentSymbol}: {contractPaymentBalance.toFixed(2)}
                    </div>
                    <div className="w-100 text-white text-center">
                      {tokenSymbol}: {contractTokenBalance.toFixed(2)}
                    </div>
                  </div>
                  {contractInfo.walletRole === 1 && (
                    <Footer
                      contractInfo={contractInfo}
                      statusR1={statusR1}
                      statusR2={statusR2}
                      oneTokenPriceR1={oneTokenPriceR1}
                      oneTokenPriceR2={oneTokenPriceR2}
                      upperLimitR1={upperLimitR1}
                      upperLimitR2={upperLimitR2}
                      paymentDecimals={paymentDecimals}
                      tokenDecimals={tokenDecimals}
                      paymentSymbol={paymentSymbol}
                      tokenSymbol={tokenSymbol}
                    />
                  )}
                </div>
              ) : (
                contractInfo.walletRole === 2 && (
                  //TRADING ACCOUNT SCREEN
                  <div className="w-100 padding-5">
                    <div className="text-white fz-1-5cqw fw700 text-center margin-b-2">
                      {contractInfo.walletRole > 0 && `TRADING ACCOUNT`}
                    </div>
                    <div className="w-100 margin-b-2 text-white">
                      {paymentSymbol} Balance:{" "}
                      {maxUserPaymentBalance.toFixed(2)}
                    </div>
                    <div className="w-100 margin-b-2 text-white">
                      {tokenSymbol} Balance: {maxUserTokenBalance.toFixed(2)}
                    </div>
                    <div className="w-100 fz-1cqw margin-t-3 text-white">
                      <div className="d-flex w-100 justify-content-center align-items-center">
                        <Link
                          to={`${process.env.REACT_APP_NETWORKURL}address/${process.env.REACT_APP_CONTRACT_ADDRESS}`}
                          target="_blank"
                          className="padding-0-1 bg-color-white border-radius-8"
                        >
                          view Private Sale Contract
                        </Link>
                      </div>
                      <div className="w-100 text-white text-center">
                        {paymentSymbol}: {contractPaymentBalance.toFixed(2)}
                      </div>
                      <div className="w-100 text-white text-center">
                        {tokenSymbol}: {contractTokenBalance.toFixed(2)}
                      </div>
                    </div>
                    {contractInfo.walletRole === 2 && (
                      <Footer
                        contractInfo={contractInfo}
                        statusR1={statusR1}
                        statusR2={statusR2}
                        oneTokenPriceR1={oneTokenPriceR1}
                        oneTokenPriceR2={oneTokenPriceR2}
                        upperLimitR1={upperLimitR1}
                        upperLimitR2={upperLimitR2}
                        paymentDecimals={paymentDecimals}
                        tokenDecimals={tokenDecimals}
                        paymentSymbol={paymentSymbol}
                        tokenSymbol={tokenSymbol}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </div>
          <div className="col-md-4 col-2"></div>
        </div>
      </div>
    </div>
  );
};

export default App;
