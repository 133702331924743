import {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import { formatBalance } from "./Utils";
import { Contract, providers } from "ethers";
import PrivateSale from "../abis/contract/PrivateSale.json";
import PaymentToken from "../abis/PaymentToken.json";
import SaleToken from "../abis/SaleToken.json";

const disconnectedState = { accounts: [], balance: "", chainId: "" };
const defContractState = {
  contract: null,
  paymentContract: null,
  tokenContract: null,
  walletRole: 0,
  userWhiteListedR1: null,
  userWhiteListedR2: null,
};

const MetaMaskContext = createContext();

export const MetaMaskContextProvider = ({ children }) => {
  const [hasProvider, setHasProvider] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    window.ethereum ? "" : "notMM"
  );
  const clearError = () => setErrorMessage("");
  const [wallet, setWallet] = useState(disconnectedState);
  const [contractInfo, setContractInfo] = useState(defContractState);
  const [pageLoading, setPageLoading] = useState(false);

  const _loadWallet = useCallback(async (providedAccounts) => {
    if (window.ethereum) {
      const chainId = await window.ethereum.request({
        method: "eth_chainId",
      });

      if (
        chainId.toLowerCase() !== process.env.REACT_APP_NETWORKID.toLowerCase()
      ) {
        try {
          setErrorMessage("wallet_switchEthereumChain");
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: process.env.REACT_APP_NETWORKID }],
          });
        } catch (error) {
          if (error.code === 4902) {
            setErrorMessage("wallet_addEthereumChain");
          }
          console.log(error);
        }
      } else {
        setErrorMessage("");
        setPageLoading(true);
        try {
          const accounts =
            providedAccounts ||
            (await window.ethereum.request({ method: "eth_accounts" }));

          if (accounts.length === 0) {
            setWallet(disconnectedState);
            setContractInfo(defContractState);
            setPageLoading(false);
            return;
          }

          const balance = formatBalance(
            await window.ethereum.request({
              method: "eth_getBalance",
              params: [accounts[0], "latest"],
            })
          );

          setWallet({ accounts, balance, chainId });

          // set Contract
          const provider = new providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const psContract = new Contract(
            process.env.REACT_APP_CONTRACT_ADDRESS,
            PrivateSale.abi,
            signer
          );

          if (psContract) {
            const paymentTokenAddress = await psContract.paymentToken();
            const saleTokenAddress = await psContract.saleToken();

            const paymentContract = new Contract(
              paymentTokenAddress,
              PaymentToken.abi,
              signer
            );

            const tokenContract = new Contract(
              saleTokenAddress,
              SaleToken.abi,
              signer
            );

            if (paymentContract && tokenContract) {
              setContractInfo({
                contract: psContract,
                paymentContract,
                tokenContract,
                walletRole:
                  accounts[0].toLowerCase() ===
                  (await psContract.deployer()).toLowerCase()
                    ? 1
                    : accounts[0].toLowerCase() ===
                      (await psContract.tradingAccount()).toLowerCase()
                    ? 2
                    : 3,
                userWhiteListedR1:
                  accounts.length > 0 &&
                  (await psContract.wlAddress(1, accounts[0])),
                userWhiteListedR2:
                  accounts.length > 0 &&
                  (await psContract.wlAddress(2, accounts[0])),
              });
              setPageLoading(false);
            }
          }
        } catch (error) {
          setPageLoading(false);
          setErrorMessage("Non-200");
          console.log("useMM:", error);
        }
      }
    } else {
      setPageLoading(false);
      console.log(
        "MetaMask is not installed. Please consider installing it: https://metamask.io/download.html"
      );
    }
  }, []);

  // useCallback ensures that you don't uselessly recreate the _updateWallet function on every render
  const _updateWallet = useCallback(async (providedAccounts) => {
    _loadWallet(providedAccounts);
  }, []);

  const updateWalletAndAccounts = useCallback(
    () => _updateWallet(),
    [_updateWallet]
  );
  const loadWallet = useCallback(
    (accounts) => _loadWallet(accounts),
    [_loadWallet]
  );
  const updateWallet = useCallback(
    (accounts) => _updateWallet(accounts),
    [_updateWallet]
  );

  useEffect(() => {
    const getProvider = async () => {
      const provider = await detectEthereumProvider({ silent: true });
      setHasProvider(Boolean(provider));

      if (provider) {
        loadWallet();
        window.ethereum.on("accountsChanged", updateWallet);
        window.ethereum.on("chainChanged", updateWalletAndAccounts);
      }
    };

    getProvider();

    return () => {
      window.ethereum?.removeListener("accountsChanged", updateWallet);
      window.ethereum?.removeListener("chainChanged", updateWalletAndAccounts);
    };
  }, [loadWallet, updateWallet, updateWalletAndAccounts]);

  const connectMetaMask = async () => {
    setIsConnecting(true);

    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      clearError();
      updateWallet(accounts);
    } catch (err) {
      // setErrorMessage(err.message);
      console.log(err.message);
      if (!window.ethereum) {
        setErrorMessage("notMM");
        setPageLoading(false);
      }
    }

    setIsConnecting(false);
  };

  const disConnectMetaMask = async () => {
    setWallet(disconnectedState);
    setContractInfo(defContractState);
    setIsConnecting(false);
    setPageLoading(false);
  };

  return (
    <MetaMaskContext.Provider
      value={{
        wallet,
        contractInfo,
        hasProvider,
        error: !!errorMessage,
        errorMessage,
        isConnecting,
        pageLoading,
        connectMetaMask,
        disConnectMetaMask,
        clearError,
      }}
    >
      {children}
    </MetaMaskContext.Provider>
  );
};

export const useMetaMask = () => {
  const context = useContext(MetaMaskContext);
  if (context === undefined) {
    throw new Error(
      'useMetaMask must be used within a "MetaMaskContextProvider"'
    );
  }
  return context;
};
