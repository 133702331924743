import { useState, useEffect } from "react";

//Deployer & tradingAcc footer
const Footer = ({
  contractInfo,
  statusR1,
  statusR2,
  oneTokenPriceR1,
  oneTokenPriceR2,
  upperLimitR1,
  upperLimitR2,
  paymentDecimals,
  tokenDecimals,
  paymentSymbol,
  tokenSymbol,
}) => {
  const [wonSoldR1, setWonSoldR1] = useState([]);
  const [wonSoldR2, setWonSoldR2] = useState([]);

  const getWonSold = async () => {
    console.log("adawdawdwa")
    let totalPaymentAmountR1 = 0;
    let totalPaymentAmountR2 = 0;
    let totalTokenAmountR1 = 0;
    let totalTokenAmountR2 = 0;
    const usersPerStreamsCountR1 = Number(
      (await contractInfo.contract.getReadValues(1)).usersPerStreamsCount
    );
    const usersPerStreamsCountR2 = Number(
      (await contractInfo.contract.getReadValues(2)).usersPerStreamsCount
    );

    for (let i = 0; i < usersPerStreamsCountR1; i++) {
      const address = (await contractInfo.contract.usersPerStreams(1, i))
        .recipient;
      const paymentAmount = Number(
        await contractInfo.contract.userPayments(1, address)
      );
      const tokenAmount = Number(
        (await contractInfo.contract.usersPerStreams(1, i)).amount
      );

      totalPaymentAmountR1 = totalPaymentAmountR1 + paymentAmount;
      totalTokenAmountR1 = totalTokenAmountR1 + tokenAmount;
    }

    for (let i = 0; i < usersPerStreamsCountR2; i++) {
      const address = (await contractInfo.contract.usersPerStreams(2, i))
        .recipient;
      const paymentAmount = Number(
        await contractInfo.contract.userPayments(2, address)
      );
      const tokenAmount = Number(
        (await contractInfo.contract.usersPerStreams(2, i)).amount
      );

      totalPaymentAmountR2 = totalPaymentAmountR2 + paymentAmount;
      totalTokenAmountR2 = totalTokenAmountR2 + tokenAmount;
    }

    setWonSoldR1([
      Math.floor(totalPaymentAmountR1 / paymentDecimals),
      Math.floor(totalTokenAmountR1 / tokenDecimals),
    ]);
    setWonSoldR2([
      Math.floor(totalPaymentAmountR2 / paymentDecimals),
      Math.floor(totalTokenAmountR2 / tokenDecimals),
    ]);
  };

  // if (contractInfo.contract && paymentDecimals && tokenDecimals) {
  //   getWonSold();
  // }

  useEffect(() => {
    if (contractInfo.contract && paymentDecimals && tokenDecimals) {
      getWonSold();
    }
  }, [contractInfo.contract, paymentDecimals, tokenDecimals]);

  return (
    <>
      {contractInfo.contract && paymentDecimals && tokenDecimals && (
        <div className="w-100 fz-075cqw text-white margin-b-0 grid">
          <div
            className="grid-item fz-1cqw"
            style={{ justifyContent: "center" }}
          >
            Round 1 information
          </div>
          <div
            className="grid-item fz-1cqw"
            style={{ justifyContent: "center" }}
          >
            Round 2 information
          </div>
          <div className="grid-item">
            Status:
            {statusR1 === 0 ? (
              <b className="padding-l-2 text-danger">Deactive</b>
            ) : statusR1 === 1 ? (
              <b className="padding-l-2 text-success">Active</b>
            ) : statusR1 === 2 ? (
              <b className="padding-l-2 text-info">Claim</b>
            ) : null}
          </div>
          <div className="grid-item">
            Status:
            {statusR2 === 0 ? (
              <b className="padding-l-2 text-danger">Deactive</b>
            ) : statusR2 === 1 ? (
              <b className="padding-l-2 text-success">Active</b>
            ) : statusR2 === 2 ? (
              <b className="padding-l-2 text-info">Claim</b>
            ) : null}
          </div>
          <div className="grid-item">
            Token Price:
            <b className="padding-l-2">
              {oneTokenPriceR1 > 0 &&
                paymentDecimals > 0 &&
                oneTokenPriceR1 / paymentDecimals}{" "}
              {paymentSymbol}
            </b>
          </div>
          <div className="grid-item">
            Token Price:
            <b className="padding-l-2">
              {oneTokenPriceR2 > 0 &&
                paymentDecimals > 0 &&
                oneTokenPriceR2 / paymentDecimals}{" "}
              {paymentSymbol}
            </b>
          </div>
          <div className="grid-item">
            Upper Limit:
            <b className="padding-l-2">
              {upperLimitR1 > 0 &&
                paymentDecimals > 0 &&
                upperLimitR1 / paymentDecimals}{" "}
              {paymentSymbol}
            </b>
          </div>
          <div className="grid-item">
            Upper Limit:
            <b className="padding-l-2">
              {upperLimitR2 > 0 &&
                paymentDecimals > 0 &&
                upperLimitR2 / paymentDecimals}{" "}
              {paymentSymbol}
            </b>
          </div>
          <div className="grid-item">
            {paymentSymbol} won:
            <b className="padding-l-2">
              {wonSoldR1.length > 0 && wonSoldR1[0]}
            </b>
          </div>
          <div className="grid-item">
            {paymentSymbol} won:
            <b className="padding-l-2">
              {wonSoldR2.length > 0 && wonSoldR2[0]}
            </b>
          </div>
          <div className="grid-item">
            {tokenSymbol} Sold:
            <b className="padding-l-2">
              {wonSoldR1.length > 0 && wonSoldR1[1]}
            </b>
          </div>
          <div className="grid-item">
            {tokenSymbol} Sold:
            <b className="padding-l-2">
              {wonSoldR2.length > 0 && wonSoldR2[1]}
            </b>
          </div>
        </div>
      )}
    </>
  );
};
export default Footer;
