import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { BrowserRouter } from "react-router-dom";
import { MetaMaskContextProvider } from "./utility/useMetamask";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <MetaMaskContextProvider>
      <App />
    </MetaMaskContextProvider>
  </BrowserRouter>
);
