export const formatBalance = (rawBalance) => {
  // console.log(rawBalance)
  const balance = Math.floor(parseInt(rawBalance) / 1000000000000000000);
  // console.log(balance)
  return balance;
};

export const formatPeymentBalance = (rawBalance) => {
  // console.log(rawBalance)
  const balance = Math.floor(parseInt(rawBalance) / 1000000);
  // console.log(balance)
  return balance;
};

export const formatChainAsNum = (chainIdHex) => {
  const chainIdNum = parseInt(chainIdHex);
  return chainIdNum;
};

export const formatAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(
    addr.length - 4,
    addr.length
  )}`;
};
